﻿(function (doc) {
  doc.addEventListener('DOMContentLoaded', function () {
    var element = doc.getElementById('AccessCode_Value');
    var label = doc.getElementById('access-code-label');
    if (element && label) {
      element.addEventListener('focus', function () {
        label.classList.add('active');
      });
      element.addEventListener('blur', function () {
        label.classList.remove('active');
      });
      element.addEventListener('input', function () {
        if (element.value.trim()) {
          label.setAttribute('data-dirty', 'true');
        } else {
          label.setAttribute('data-dirty', 'false');
        }
      });
    }
  });
})(document);